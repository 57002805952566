/*
 |-----------------------------------------------------------------------------
 | components/atoms/Error/Error.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as IError from './types';

const Error: FC<IError.IProps> = ({ text, utilities }) => {
	// ray('Debug atom Error:', {
	// 	text: text,
	// 	utilities: utilities,
	// }).red();

	return (
		<span
			className={`
				block
				text-lg
				text-red-500
				${utilities ? utilities : null}
			`}
			data-testid="error"
		>
			{text}
		</span>
	);
};

export default Error;
