/*
 |-----------------------------------------------------------------------------
 | components/atoms/Input/Input.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import Error from '@/atoms/Error';

import * as IInput from './types';

const Input: FC<IInput.IProps> = ({
	hideLabel,
	id,
	isDisabled,
	isInvalid,
	isOptional,
	label,
	name,
	onBlur,
	onChange,
	placeholder,
	type,
	utilities,
	variant,
}) => {
	// ray('Debug atom Input:', {
	// 	hideLabel: hideLabel,
	// 	id: id,
	// 	isDisabled: isDisabled,
	// 	isInvalid: isInvalid,
	// 	isOptional: isOptional,
	// 	label: label,
	// 	name: name,
	// 	placeholder: placeholder,
	// 	type: type,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).red();

	let classes;
	switch (variant) {
		case 'simple':
			classes = `
				border-gray-300
				rounded-md
				shadow-sm
				focus:border-indigo-300
				focus:ring
				focus:ring-indigo-200
				focus:ring-opacity-50
			`;
			break;
		case 'solid':
			classes = `
				bg-gray-100
				border-transparent
				rounded-md
				focus:bg-white
				focus:border-gray-500
				focus:ring-0
			`;
			break;
		case 'underline':
			classes = `
				bg-transparent
				border-0
				border-b
				border-brand-1
				mt-0
				px-0.5
				focus:ring-0 focus:border-brand-4
				placeholder:text-neutral-400
			`;
			break;
		default:
			classes = '';
	}

	return (
		<label
			className={`
				block
				cursor-pointer
				text-xl
				text-brand-1
				sm:text-lg
				${utilities ? utilities : ''}
			`}
			data-testid="input"
			htmlFor={id}
		>
			<span className={hideLabel && `sr-only`}>{label}</span>
			<input
				className={`
					block
					caret-brand-4
					mt-1
					w-full
					${classes}
					disabled:opacity-25
				`}
				disabled={isDisabled}
				id={id}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				placeholder={placeholder}
				required={!isOptional}
				type={type}
			/>
			{isInvalid && (
				<Error text={`${name} is required`} utilities={`mt-1`} />
			)}
		</label>
	);
};

export default Input;
